import PropTypes from 'prop-types'

import styles from './panels.module.css'

export const Panels = ({ children }) => {
    return (
      <div className={styles.panels}>
        {children}
      </div>
    )
}

Panels.propTypes = {
  children: PropTypes.node.isRequired
}