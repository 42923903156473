import { callAPI } from '.'

export const getLanguages = async (params) => {
    return callAPI('v1/GetCultures', 'GET', params)
}

export const getLanguage = async (cultureId) => {
    const languages = await getLanguages()
    return languages.find(lang => lang.id.toLowerCase() == cultureId)
}

export const addLanguage = async (params) => {
    return callAPI('v1/AddCulture', 'POST', params)
}

export const updateLanguage = async (params) => {
    return callAPI('v1/UpdateCulture', 'POST', params)
}