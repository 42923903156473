import PropTypes from 'prop-types'
import { useRef, lazy, Suspense } from 'react'

import { useI18n } from '@/context'
import { Icon, Modal } from "@/components"

const InfoForm = lazy(() => import('@/components/forms/InfoForm'))

import styles from './info-bar.module.css'

export const InfoBar = ({ organisation }) => {
    const i18n = useI18n()

    const infoModal = useRef(null)

    const toggleInfo = () => {
        infoModal.current.toggle()
    }

    const formSubmitted = () => {
        toggleInfo()
    }
    
    return (
        <>
            <div className={styles.infoBar}>
                <button onClick={() => toggleInfo()} data-woo="foo">
                    <Icon name="info" /> {i18n.t('web.private.misc.info')}
                </button>
            </div>

            <Modal ref={infoModal} title={organisation.name} variant="fixed">
                <Suspense><InfoForm organisation={organisation} onSuccess={formSubmitted} /></Suspense>
            </Modal>
        </>
    )
} 

InfoBar.propTypes = {
    organisation: PropTypes.object
}