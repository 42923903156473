import { callAPI } from '.'

export const getTranslations = async (params) => {
    const translations = await callAPI('v1/GetTranslations', 'GET', params)

    // Map object list response from API into a more convenient object keyed on translation keys
    return translations.reduce((m, o) => {
        m[o['key']] = (o.texts && o.texts[0]) ? o.texts[0]['text'] : null
        return m
    }, {})
}

export const addTranslations = async (params) => {
    return callAPI('v1/AddTranslations', 'POST', params)
}

export const updateTranslations = async (params) => {
    return callAPI('v1/UpdateTranslations', 'POST', params)
}

export const importTranslations = async (params) => {
    return callAPI('v2/resources/text/bulk-upsert', 'POST', params)
}