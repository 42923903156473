import { createContext, useContext } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import { getSalesRegions, getCountries, getOrganisations } from '@/api'
import { useAuth, useI18n } from '@/context'
import { canUseMyOrgsScope, Roles } from '@/config'

import { ORGANISATION_ID_LENGTH, TRISTEL_MASTER_ORG_ID } from '@/utils'
import { BlockSpinner } from '../components'

const ScopeContext = createContext()

const deriveScopeSlugFromPath = (path) => {
    return path?.split('/').filter(Boolean)[1]?.toLowerCase()
}

export const ScopeProvider = ({ children }) => {
    const auth = useAuth()
    const i18n = useI18n()
    const location = useLocation()

    let scope = { id: 'none' }

    const slug = deriveScopeSlugFromPath(location.pathname)

    const needRegions = slug && slug.endsWith('-region')
    const needCountries = slug && slug.endsWith('-country')
    const needOrganisations = slug && (slug === 'my-orgs' || slug?.length === ORGANISATION_ID_LENGTH)

    const { data: regions } = useSWR((auth.user && needRegions) ? 'sales-regions' : null, getSalesRegions)
    const { data: countries } = useSWR((auth.user && needCountries) ? 'countries' : null, getCountries)
    const { data: organisations } = useSWR((auth.user && needOrganisations) ? 'organisations' : null, getOrganisations)

    if (slug) {

        if (slug === 'global') {
            if (!auth.user) return <Navigate to={`/${i18n.locale}/login/`} replace={true} />

            scope = {
                slug: slug,
                id: slug,
                name: i18n.t('web.private.misc.global'),
                isTristel: true,
                orgId: TRISTEL_MASTER_ORG_ID,
                orgName: 'Tristel Solutions',
                masterOrgId: TRISTEL_MASTER_ORG_ID,
                type: 'global',
                parentType: null,
                parent: null,
                salesRep: null,
                masterOrgFilter: {},
                orgFilter: {
                    masterOrganisation: {
                        id: TRISTEL_MASTER_ORG_ID
                    }
                },
                userParams: {
                    parentOrganisationId: TRISTEL_MASTER_ORG_ID
                },
                region: null,
                country: null,
                organisation: null
            }
        } else if (slug.endsWith('-region')) {
            if (!auth.user) return <Navigate to={`/${i18n.locale}/login/`} replace={true} />

            if (!regions) return <BlockSpinner height='100dvh' />
            const region = regions.find(r => r.id == slug.replace('-region', ''))
            if (!region) throw new Error('Page not found')
    
            scope = {
                slug: slug,
                id: region.id,
                name: region.name,
                isTristel: true,
                orgId: TRISTEL_MASTER_ORG_ID,
                orgName: 'Tristel Solutions',
                masterOrgId: TRISTEL_MASTER_ORG_ID,
                type: 'regional',
                parentType: 'global',
                parent: 'global',
                salesRep: null,
                masterOrgFilter: {
                    country: {
                        commercialZone: {
                            id: region.id
                        }
                    },
                },
                orgFilter: {
                    country: {
                        commercialZone: {
                            id: region.id
                        }
                    }
                },
                userParams: {
                    parentOrganisationId: TRISTEL_MASTER_ORG_ID,
                    salesRegionId: region.id
                },
                region: region,
                country: null,
                organisation: null
            }
        } else if (slug.endsWith('-country')) {
            if (!auth.user) return <Navigate to={`/${i18n.locale}/login/`} replace={true} />

            if (!countries) return <BlockSpinner height='100dvh' />
            const country = countries.find(c => c.iso3166.toLowerCase() === slug.replace('-country', ''))
            if (!country) throw new Error('Page not found')
    
            scope = {
                slug: slug,
                id: country.iso3166,
                name: country.description,
                isTristel: true,
                orgId: TRISTEL_MASTER_ORG_ID,
                orgName: 'Tristel Solutions',
                masterOrgId: TRISTEL_MASTER_ORG_ID,
                type: 'country',
                parentType: 'regional',
                parent: country.commercialZone.id + '-region',
                salesRep: null,
                masterOrgFilter: {
                    country: {
                        iso3166: country.iso3166.toUpperCase()
                    },
                },
                orgFilter: {
                    country: {
                        iso3166: country.iso3166.toUpperCase()
                    }
                },
                userParams: {
                    parentOrganisationId: TRISTEL_MASTER_ORG_ID,
                    country: country.iso3166.toUpperCase()
                },
                region: country.commercialZone,
                country: country,
                organisation: null
            }
        } else if (slug == 'my-orgs') {
            if (!auth.user) return <Navigate to={`/${i18n.locale}/login/`} replace={true} />

            if (!organisations) return <BlockSpinner height='100dvh' />

            scope = {
                slug: slug,
                id: slug,
                name: i18n.t('web.private.misc.my_organisations'),
                isTristel: false,
                orgId: auth.user?.masterOrganisation?.id,
                orgName: auth.user?.masterOrganisation?.name,
                masterOrgId: auth.user?.masterOrganisation.id,
                type: 'multi-org',
                parentType: null,
                parent: null,
                salesRep: null,
                masterOrgFilter: {},
                orgFilter: {}, // No need for explicit filter as API will only return correct orgs
                userParams: {}, // No need for explicit filter as API will only return correct users
                region: null,
                country: null,
                organisations: organisations,
                organisation: null,
                products: Array.from(new Set(organisations.flatMap(o => o.products?.map(x => x.product) || []).map(JSON.stringify))).map(JSON.parse)
            }
        } else if (slug.length === ORGANISATION_ID_LENGTH) {
            if (!auth.user) return <Navigate to={`/${i18n.locale}/login/`} replace={true} />

            if (!organisations) return <BlockSpinner height='100dvh' />
            const organisation = organisations.find(o => o.id == slug)
            if (!organisation) throw new Error('Page not found')
    
            scope = {
                slug: slug,
                id: organisation.id,
                name: organisation.name,
                isTristel: false,
                orgId: organisation.id,
                orgName: organisation.name,
                masterOrgId: organisation.masterOrganisation.id,
                appInstallCode: organisation.appCode,
                type: 'single-org',
                parentType: (auth.user.role === Roles.multiOrgAdmin || auth.user.role === Roles.salesRep) ? 'multi-org' : 'country',
                parent: (canUseMyOrgsScope(auth.user) && organisations.length > 1) ? 'my-orgs' : organisation.country?.iso3166?.toLowerCase() + '-country',
                salesRep: organisation.salesRepUserId ? {
                    id: organisation.salesRepUserId,
                    name: organisation.salesRepName,
                    phone: organisation.salesRepPhoneNumber
                } : null,
                masterOrgFilter: {},
                userParams: {
                    organisationId: organisation.id
                },
                region: organisation.country.commercialZone,
                country: organisation.country,
                organisation: organisation,
                products: organisation.products?.map(x => x.product) || []
            }
        } else if (slug == 'user') {
            if (!auth.user) return <Navigate to={`/${i18n.locale}/login/`} replace={true} />

            scope = {
                slug: slug,
                id: slug,
                name: `${auth.user.userInfo.firstName} ${auth.user.userInfo.lastName}`,
                isTristel: false,
                type: 'user',
                parent: '..'
            }
        }

        if (scope && scope.id) document.documentElement.setAttribute('data-scope', scope.id)
    }

    return (
        <ScopeContext.Provider value={scope}>
            {children}
        </ScopeContext.Provider>
    )
}

ScopeProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export const useScope = () => useContext(ScopeContext);
