import { callAPI } from '.'

// Data regions are locations of servers - i.e. where sensitive data is held (legal reasons)

export const getDataRegions = async () => {
    return callAPI('v2/regions/data', 'GET')
}

export const getDataRegion = async (name) => {
    const regions = await getDataRegions()
    return regions?.find(region => region.name.toLowerCase() === name.toLowerCase())
}

// Sales regions are geographic areas - eg. continents or similar (sales reporting reasons)

export const getSalesRegions = async () => {
    return callAPI('v1/GetCommercialZones', 'GET')
}

export const addSalesRegion = async (params) => {
    return callAPI('v1/AddCommercialZone', 'POST', params)
}

export const updateSalesRegion = async (params) => {
    return callAPI('v1/UpdateCommercialZone', 'POST', params)
}