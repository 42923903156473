import PropTypes from 'prop-types'
import { useI18n } from '@/context'

import styles from './sales-rep-panel.module.css'

export const SalesRepPanel = ({salesRep}) => {
    const i18n = useI18n()
    
    return salesRep ? (
       <div className={styles.salesRepPanel}>
           <p>{i18n.t('web.private.misc.your_representative')}</p>
           <h2>{salesRep.name}</h2>
           { salesRep.phone && <p><a href={`tel:${salesRep.phone?.replaceAll(' ', '')}`}>{salesRep.phone}</a></p> }
       </div>
    ) : null
} 

SalesRepPanel.propTypes = {
    salesRep: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string
    })
}