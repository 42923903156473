import PropTypes from 'prop-types'
import { useState } from 'react'

import { useI18n } from '@/context'

import styles from './data-grid.module.css'

function DataImage ({src, alt}) {
    const i18n = useI18n()
    const [url, setUrl] = useState(src)

    const handleImageError = () => {
        setUrl(null)
    }

    return (
        url ?
        <img src={url} alt={alt} onError={handleImageError} />
        :
        <span>{i18n.t('web.private.misc.no_image_available')}</span>
    )
}

DataImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
}

function DataCell ({cell, columns, getImageURL, getCellState, decorator, onSelect}) {

    return <li onClick={onSelect ? () => onSelect(cell) : null} style={{ cursor: onSelect ? 'pointer' : 'default' }} data-state={getCellState && getCellState(cell)}>
            <DataImage
                src={getImageURL(cell)}
                alt="Product image"
            />
            <strong>{columns.map(column => column(cell))}</strong>
            {decorator && decorator(cell)}
    </li>
}

DataCell.propTypes = {
    cell: PropTypes.object.isRequired, 
    columns: PropTypes.array.isRequired,
    getImageURL: PropTypes.func.isRequired,
    decorator: PropTypes.func,
    getCellState: PropTypes.func,
    onSelect: PropTypes.func
}

export function DataGrid ({columns, data, getImageURL, getCellState, decorator, onSelect, children}) {
    return (
        <ul className={styles.dataGrid}>
            {data.map( (cell) => <DataCell key={cell} cell={cell} columns={columns} getImageURL={getImageURL} getCellState={getCellState} onSelect={onSelect} decorator={decorator} /> )}
            { children && <li>{children}</li> }
        </ul>
    )
}

DataGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    getImageURL: PropTypes.func.isRequired,
    decorator: PropTypes.func,
    getCellState: PropTypes.func,
    onSelect: PropTypes.func,
    children: PropTypes.node
}