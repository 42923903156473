import { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { useAuth } from '@/context'
import { getTranslations, getLanguages } from '@/api'
import { BlockSpinner, LanguageSelector, Modal } from '@/components'

import fallback from '@/translations/default.json'

const DEFAULT_LOCALE = 'en-GB'
const DEFAULT_LANGUAGE = 'English (GB)'
const ENTRY_LOCALE = window.location.pathname.split('/').filter(Boolean)[0]
const HAVE_ENTRY_LOCALE = Boolean(ENTRY_LOCALE)
const ASSUMED_LOCALE = ENTRY_LOCALE || navigator.language?.toLowerCase() || DEFAULT_LOCALE

const I18nContext = createContext()

export const I18nProvider = ({children}) => {
    const [locale, setLocale] = useState(DEFAULT_LOCALE.toLowerCase())
    const [deliberateLocaleChoice, setDeliberateLocalChoice] = useState(HAVE_ENTRY_LOCALE)
    const [language, setLanguage] = useState(DEFAULT_LANGUAGE)
    const modalRef = useRef(null)
    const auth = useAuth()

    const normaliseLocaleString = (l) => {
        return l.replace(/([a-z]{2})$/, (c) => c.toUpperCase())
    }

    const { data: languages } = useSWR('languages', getLanguages)

    const translationFetcher = async (cacheKey, locale) => {
        console.log('Getting translations for', locale)
        return getTranslations({
            cultureId: normaliseLocaleString(locale),
            availability: 'WEB'
        })
    }
    // Use 'auth' as a dependency so reloads on login/logout as some translations are not public
    // If default locale sames as current locale then API only hit once due to caching of duped requests
    const { data: defaultTranslations } = useSWRImmutable(['translations', normaliseLocaleString(DEFAULT_LOCALE), auth['user'] ? true : false], translationFetcher)
    const { data: translations } = useSWRImmutable(['translations', normaliseLocaleString(locale), auth['user'] ? true : false], translationFetcher)

    useEffect(() => {
        const lang = languages && languages.find(lang => lang.id.toLowerCase() === ASSUMED_LOCALE.toLowerCase())
        if (lang) {
            setLocale(ASSUMED_LOCALE)
            setLanguage(lang.language)
        }
    }, [languages])

    useLayoutEffect(() => {
        window.document.documentElement.lang = locale?.split('-')[0] || 'en'
    }, [locale])

    const changeLocale = (newLocale) => {
        if (newLocale && newLocale.toLowerCase() != locale) {
            setDeliberateLocalChoice(true)
            setLocale(newLocale.toLowerCase())
            setLanguage(languages.find(lang => lang.id === newLocale)?.language)
        }
    }

    const  parseMessageParams = (message, params) => {
        if (params == null) return message
        return Object.keys(params).reduce((parsed, key) =>
            parsed.replace(
                new RegExp(`{${key}}`, "gi"),
                params[key],
            ),
            message
        );
    }

    // Try to translate a provided key (k)
    // First, see if have translation in current locale
    // If not, then try in default locale,
    // If still no good, then use hardcoded default
    // If all else fails then just return the key itself
    const translate = (k, params) => {
        const key = k.toUpperCase()
        let result
        if (translations && translations[key]) {
            result = translations[key]
        } else if (defaultTranslations && defaultTranslations[key]) {
            result = defaultTranslations[key]
        } else {
            result = fallback[k] || key
        }
        return parseMessageParams(result, params)
    }

    const t = translate

    const toggleLanguageSelector = () => {
        modalRef.current.toggle()
    }

    return (languages && translations) ? 
        <I18nContext.Provider value={{locale, language, t, languages, DEFAULT_LOCALE, deliberateLocaleChoice, changeLocale, toggleLanguageSelector, normaliseLocaleString}}>
            {children}
            <Modal ref={modalRef} title={t('web.private.misc.select_language')}><LanguageSelector languages={languages} key={languages} /></Modal>
        </I18nContext.Provider>
        :
        <BlockSpinner height="100vh" />
}

I18nProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export const useI18n = () => useContext(I18nContext);
