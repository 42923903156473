import PropTypes from 'prop-types'
import { useRef, lazy, Suspense } from 'react'
import { Link } from 'react-router-dom'

import styles from './detail-table.module.css'

import { useI18n, useAuth } from '@/context'
import { Roles, Actions, Resources } from '@/config'
import { Authorised, Icon, Image, Modal } from '@/components'
import { showLocalDateTime, deriveUTCOffsetFromTimestamp, showHumaneSeconds } from '@/utils'

const EditDisinfectionLogForm = lazy(() => import('@/components/forms/EditDisinfectionLogForm'))

import { DESTINATION_STORAGE, DESTINATION_READY, DESTINATION_PATIENT } from '@/utils'

export const DisinfectionLogDetailTable = ({ entry, audit, mutate }) => {
    const i18n = useI18n()
    const auth = useAuth()

    const editModal = useRef(null)
    const toggleEditModal = () => editModal.current.toggle()

    const destinationTranslationKeys = {
        [DESTINATION_STORAGE]: 'web.private.misc.device_destinations.storage',
        [DESTINATION_PATIENT]: 'web.private.misc.device_destinations.patient',
        [DESTINATION_READY]: 'web.private.misc.device_destinations.ready'
    }

    const logUpdated = () => {
        mutate()
        toggleEditModal()
    }

    const timezoneOffset = deriveUTCOffsetFromTimestamp(entry.timestamp)

    return (
        <>
            <div className={styles.logDetail}>
                <div className={styles.detailSnack}>
                    <p className={styles.state}>
                        { entry.voided ?
                            <>
                                <strong>VOIDED</strong>
                            </>
                            :
                            <>
                                {i18n.t('web.private.misc.table.header.cycle_code')}
                                <strong>{entry.cycleCode}</strong>
                            </>
                        }
                    </p>
                    <p className={styles.productImage}>
                        { entry.medicalDevice && <Image filename={`device-${entry.medicalDevice?.id}-image`} alt={entry.medicalDevice?.tag}>
                            { entry.medicalDevice?.deviceTypeId ? 
                                <Image filename={`device-type-${entry.medicalDevice?.deviceTypeId}-image`} alt={entry.medicalDevice.deviceTypeName}>
                                    <span className="no-image">{i18n.t('web.private.misc.no_image_available')}</span>
                                </Image>
                            :
                                <span className="no-image">{i18n.t('web.private.misc.no_image_available')}</span>
                            }
                        </Image> }
                     </p>
                </div>
                <table className={styles.detailTable}>
                    <tbody>
                    <tr>
                        <th>{i18n.t('web.private.misc.table.header.date')}</th>
                        <td>
                            {showLocalDateTime(entry.timestamp, timezoneOffset, i18n.locale)} {entry.timezoneName}

                            { (timezoneOffset != (new Date()).getTimezoneOffset() * -1) &&
                                <span style="color: #FFF8;">{ ' ' }(
                                {new Date(entry.timestamp).toLocaleTimeString(
                                    i18n.locale,
                                    {timeZoneName: 'short', hour: 'numeric', minute: 'numeric' }
                                )}
                                )</span>
                            }
                        </td>
                    </tr>
                    { entry.externalCompletionCodes?.map(code => <tr key={code}>
                        <th>{code.type}</th>
                        <td>
                            {code.codes ? <>
                                <ol style={{ marginTop: '-1rem' }}>
                                    { code.codes.CompletionCode && <li>{i18n.t('web.private.misc.table.header.stella_completion_code')}: {code.codes.CompletionCode}</li> }
                                    { code.codes.CompletionCodeNumber && <li>{i18n.t('web.private.misc.table.header.stella_completion_code_number')}: {code.codes.CompletionCodeNumber}</li> }
                                    { code.codes.IQSerialNumber && <li>{i18n.t('web.private.misc.table.header.stella_iq_serial_number')}: {code.codes.IQSerialNumber}</li> }
                                    { code.codes.PulseSerialNumber && <li>{i18n.t('web.private.misc.table.header.stella_pulse_serial_number')}: {code.codes.PulseSerialNumber}</li> }
                                </ol>
                            </> : <>
                                {code.value}
                            </>}
                        </td>
                    </tr>) }
                    <tr>
                        <th>{i18n.t('web.private.misc.table.header.device_type')}</th>
                        <td>
                            <Link to={`../?deviceTypeName=${entry.medicalDevice.deviceTypeName}`}>
                                {entry.medicalDevice.deviceTypeName || i18n.t('web.private.misc.unknown')}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <th>{i18n.t('web.private.misc.table.header.device')}</th>
                        <td>
                            <Link to={`../../medical-devices/${entry.medicalDevice?.id}/`}>
                            {entry.medicalDevice?.tag || i18n.t('web.private.misc.unknown')}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <th>{i18n.t('web.private.misc.table.header.serial_number')}</th>
                        <td><Link to={`../?device.serialNumber=${entry.medicalDevice?.serialNumber}`}>{entry.medicalDevice?.serialNumber || i18n.t('web.private.misc.unknown') }</Link></td>
                    </tr>
                    { entry.destination && <tr>
                        <th>{i18n.t('web.private.misc.table.header.destination')}</th>
                        <td>
                            <p className={styles.destination}>
                                <span>
                                    <strong>{i18n.t(destinationTranslationKeys[entry.destination])}</strong>
                                    { auth.user.role === Roles.orgAdmin && (entry.destination === DESTINATION_PATIENT) && <>
                                    {':'} <span className="gl-block">{entry.patientId}</span>
                                    </> }
                                </span>
                                { (entry.destination !== DESTINATION_STORAGE) &&
                                    <Authorised  action={Actions.update} resource={Resources.disinfectionLog}>
                                        <button onClick={() => toggleEditModal()}><Icon name="edit" /></button>
                                    </Authorised>
                                }
                            </p>
                            { audit && (audit.length > 0) && <>
                                <p>
                                    {new Date(audit[0].lastUpdated).toLocaleDateString(i18n.locale)}, {new Date(audit[0].lastUpdated).toLocaleTimeString(i18n.locale)} - {audit[0].updatedUser.firstName} {audit[0].updatedUser.lastName}
                                </p>
                                <div className={styles.audit}>
                                    <strong>{i18n.t('web.private.misc.table.header.log')}</strong>
                                    <ol>{ audit.slice(1).map((a, i) => (
                                        <li key={i}>
                                            <strong>{i18n.t(destinationTranslationKeys[a.updatedValue])}</strong><br />
                                            {new Date(a.lastUpdated).toLocaleDateString(i18n.locale)}, {new Date(a.lastUpdated).toLocaleTimeString(i18n.locale)} - {a.updatedUser.firstName} {a.updatedUser.lastName}
                                        </li>)) }
                                        <li>
                                            <strong>{i18n.t(destinationTranslationKeys[audit[audit.length-1].originalValue])}</strong><br />
                                            {new Date(entry.timestamp).toLocaleDateString(i18n.locale)}, {new Date(entry.timestamp).toLocaleTimeString(i18n.locale)} - {(entry.user) ? <>{entry.user.firstName} {entry.user.surname}</> : i18n.t('web.private.misc.unknown')}
                                        </li>
                                    </ol>
                                </div>
                            </> }
                        </td>
                    </tr> }
                    <tr>
                        <th>{i18n.t('web.private.misc.table.header.operator')}</th>
                        <td><span className="gl-block">{(entry.user) ? <>{entry.user.firstName} {entry.user.surname}</> : i18n.t('web.private.misc.unknown')}</span></td>
                    </tr>
                    {entry.durationSecs && entry.durationSecs > 0 &&<tr>
                        <th>{i18n.t('web.private.misc.table.header.duration')}</th>
                        <td>{showHumaneSeconds(entry.durationSecs, i18n.locale)}</td>
                    </tr> }
                    <tr>
                        <th>{i18n.t('web.private.misc.table.header.clinical_setting')}</th>
                        <td>
                            <Link to={`../../clinical-settings/${entry.clinicalSetting?.id}/`}>
                                {entry.clinicalSetting.name || i18n.t('web.private.misc.unknown')}
                            </Link>    
                        </td>
                    </tr>
                    <tr>
                        <th>{i18n.t('web.private.misc.table.header.product_system')}</th>
                        <td>
                            <strong>{entry.medicalDevice?.system.name}</strong>
                            { entry.products &&
                                <ol>{ entry.products.map( product =>
                                    <li key={product.id}>
                                        <strong>{product.name || i18n.t('web.private.misc.unknown')}</strong><br />
                                        {i18n.t('web.private.misc.gtin')}: {product.gtin || i18n.t('web.private.misc.unknown')}<br />
                                        {i18n.t('web.private.misc.lot')}: {product.lot || i18n.t('web.private.misc.unknown')}<br />
                                        {i18n.t('web.private.misc.expiry')}: {product.expiry ? new Date(product.expiry).toLocaleDateString(i18n.locale) : i18n.t('web.private.misc.unknown')}
                                    </li>
                                )}</ol>
                            }
                        </td>
                    </tr>

                    { entry.voided && <tr>
                        <th>{i18n.t('web.private.misc.table.header.voided')}</th>
                        <td>
                            {/* TODO: We only show the void timestamp in user local time, not also local to where it was voided like we do for logs above - make consistent? */}
                            {entry.voided.by.firstName} {entry.voided.by.surname}<br />
                            {
                                new Date(entry.voided.at).toLocaleDateString(i18n.locale)
                            }
                            {', '}
                            {
                                new Date(entry.voided.at).toLocaleTimeString(i18n.locale)
                            }<br />
                            {entry.voided.reason.replaceAll("\n", "<br>")}
                        </td>
                    </tr> }
                    </tbody>
                </table>
            </div>

            <Modal ref={editModal} title={i18n.t('web.private.page.disinfecton_log.edit_disinfection_log')}>
                <Suspense><EditDisinfectionLogForm entry={entry} onSuccess={logUpdated} /></Suspense>
            </Modal>
        </>
    )
}

DisinfectionLogDetailTable.propTypes = {
    entry: PropTypes.object.isRequired,
    audit: PropTypes.array,
    mutate: PropTypes.func
}