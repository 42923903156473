import PropTypes from 'prop-types'
import { useEffect } from 'react'

import { useInsights } from '@/context'

export const ErrorInsight = (error) => {

    const insights = useInsights()

    useEffect(() => {
        console.log('Error Insight Effect fired')
        if (insights) {
            insights.trackException({ exception: error })
            insights.flush()
        }
    }, [insights, error])
    
    return null
} 

Image.propTypes = {
    error: PropTypes.object.isRequired
}
