import PropTypes from 'prop-types'

export const Icon = ({ name, size = 16 }) => {
    const href = `#${name}`

    return (
        <svg className="icon" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <use href={href} />
        </svg>
    )
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number
}