import { useState } from 'react'
import PropTypes from 'prop-types'

import { BLOB_BASE } from '@/utils'

import styles from './avatar.module.css'

export const Avatar = ({id, name, size = 44, cacheBuster = Date.now()}) => {
    const [avatarURL, setAvatarURL] = useState(`${BLOB_BASE}/dynamic-assets/${id}.jpg?t=${cacheBuster}`)

    const usePlaceholderAvatar = () => {
        setAvatarURL('/assets/images/avatar-placeholder.svg')
    }

    return (
       <img className={styles.avatar} src={avatarURL} alt={name} width={size} height={size} onError={usePlaceholderAvatar} />
    )
} 

Avatar.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    cacheBuster: PropTypes.number
}
