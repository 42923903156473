import PropTypes from 'prop-types'

import { useI18n } from '@/context'
import { displayRoleName } from '@/utils'

import styles from './detail-table.module.css'


export const UserDetailTable = ({ user }) => {
    const i18n = useI18n()

    return (
        <div className={styles.logDetail}>
            <table className={styles.detailTable}>
                <tbody>
                <tr>
                    <th>{i18n.t('web.public.misc.table.header.name')}</th>
                    <td>{user.userInfo.firstName} {user.userInfo.lastName}</td>
                </tr>
                <tr>
                    <th>{i18n.t('web.public.misc.table.header.email')}</th>
                    <td>{user.userInfo.email}</td>
                </tr>
                <tr>
                    <th>{i18n.t('web.public.misc.table.header.role')}</th>
                    <td>{displayRoleName(user)}</td>
                </tr>
                { user.masterOrganisation && <tr>
                    <th>{i18n.t('web.public.misc.table.header.master_organisation')}</th>
                    <td>{user.masterOrganisation.name}</td>
                </tr> }
                { user.country && <>
                    <tr>
                        <th>{i18n.t('web.public.misc.table.header.country')}</th>
                        <td>{user.country.description}</td>
                    </tr>
                    { user.country.commercialZone && <tr>
                        <th>{i18n.t('web.public.misc.table.header.region')}</th>
                        <td>{user.country.commercialZone.name}</td>
                    </tr> }
                </> }
                { user.whenCreated && <tr>
                    <th>{i18n.t('web.public.misc.table.header.account_created')}</th>
                    <td> {new Date(user.whenCreated).toLocaleDateString()}</td>
                </tr> }
                </tbody>
            </table>
        </div>
    )
}

UserDetailTable.propTypes = {
    user: PropTypes.object.isRequired
}
