import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Papa from 'papaparse'
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable, useAsyncDebounce } from 'react-table'
/* eslint-disable no-unused-vars */
import regeneratorRuntime from 'regenerator-runtime' // Needed as peer depdency of useAsyncDebounce
/* eslint-enable no-unused-vars */

import { useI18n } from '@/context'
import { useOnClickOutside } from '@/hooks'
import { searchParamsToObject } from '@/utils'
import { BackLink, OptionBar, Icon } from '@/components'

import styles from './data-table.module.css'

const startOfWeek = (d) => {
    const day = d.getDay()
    const diff = d.getDate() - day + (day == 0 ? -6:1)
    return new Date(new Date(d).setDate(diff));
}

const startOfMonth = (d, countback = 0) => {
    return new Date(d.getFullYear(), d.getMonth() - countback, 1)
}

const endOfMonth = (d, countback = 0) => {
    return new Date(d.getFullYear(), d.getMonth() - countback + 1, 0)
}

const startOfYear = (d, countback = 0) => {
    return new Date(d.getFullYear() - countback, 1 - countback, 1)
}

const endOfYear = (d, countback = 0) => {
    return new Date(d.getFullYear() - countback, 12, 31)
}

const DAYS = 1000 * 60 * 60 * 24
const TODAY = new Date()

const DATE_OPTIONS = {
    'days-7': [new Date(TODAY - (7 * DAYS)), TODAY],
    'days-14': [new Date(TODAY - (14 * DAYS)), TODAY],
    'days-28': [new Date(TODAY - (28 * DAYS)), TODAY],
    'weeks-0': [startOfWeek(TODAY), TODAY],
    'weeks-1': [new Date(startOfWeek(TODAY) - (7 * DAYS)), startOfWeek(TODAY)],
    'months-0': [startOfMonth(TODAY), TODAY],
    'months-1': [new Date(startOfMonth(TODAY, 1)), endOfMonth(TODAY, 1)],
    'months-2': [new Date(startOfMonth(TODAY, 2)), endOfMonth(TODAY, 2)],
    'months-3': [new Date(startOfMonth(TODAY, 3)), endOfMonth(TODAY, 3)],
    'months-4': [new Date(startOfMonth(TODAY, 4)), endOfMonth(TODAY, 4)],
    'months-5': [new Date(startOfMonth(TODAY, 5)), endOfMonth(TODAY, 5)],
    'months-6': [new Date(startOfMonth(TODAY, 6)), endOfMonth(TODAY, 6)],
    'months-7': [new Date(startOfMonth(TODAY, 7)), endOfMonth(TODAY, 7)],
    'months-8': [new Date(startOfMonth(TODAY, 8)), endOfMonth(TODAY, 8)],
    'months-9': [new Date(startOfMonth(TODAY, 9)), endOfMonth(TODAY, 9)],
    'months-10': [new Date(startOfMonth(TODAY, 10)), endOfMonth(TODAY, 10)],
    'months-11': [new Date(startOfMonth(TODAY, 11)), endOfMonth(TODAY, 11)],
    'months-12': [new Date(startOfMonth(TODAY, 12)), endOfMonth(TODAY, 12)],
    'months-13': [new Date(startOfMonth(TODAY, 13)), endOfMonth(TODAY, 13)],
    'months-14': [new Date(startOfMonth(TODAY, 14)), endOfMonth(TODAY, 14)],
    'months-15': [new Date(startOfMonth(TODAY, 15)), endOfMonth(TODAY, 15)],
    'months-16': [new Date(startOfMonth(TODAY, 16)), endOfMonth(TODAY, 16)],
    'months-17': [new Date(startOfMonth(TODAY, 17)), endOfMonth(TODAY, 17)],
    'months-18': [new Date(startOfMonth(TODAY, 18)), endOfMonth(TODAY, 18)],
    'months-19': [new Date(startOfMonth(TODAY, 19)), endOfMonth(TODAY, 19)],
    'months-20': [new Date(startOfMonth(TODAY, 20)), endOfMonth(TODAY, 20)],
    'months-21': [new Date(startOfMonth(TODAY, 21)), endOfMonth(TODAY, 21)],
    'months-22': [new Date(startOfMonth(TODAY, 22)), endOfMonth(TODAY, 22)],
    'months-23': [new Date(startOfMonth(TODAY, 23)), endOfMonth(TODAY, 23)],
    'years-0': [new Date(startOfYear(TODAY, 0)), TODAY],
    'years-1': [new Date(startOfYear(TODAY, 1)), endOfYear(TODAY, 1)],
    'years-2': [new Date(startOfYear(TODAY, 2)), endOfYear(TODAY, 2)],
    'years-3': [new Date(startOfYear(TODAY, 3)), endOfYear(TODAY, 3)],
    'years-4': [new Date(startOfYear(TODAY, 4)), endOfYear(TODAY, 4)]
}


const KeywordGlobalFilter = ({preGlobalFilteredRows, setGlobalFilter, locale}) => {
    const i18n = useI18n()

    const count = preGlobalFilteredRows.length
    const [searchParams, setSearchParams] = useSearchParams()

    const onChange = useAsyncDebounce(event => {
        const existing = searchParamsToObject(searchParams)
        setSearchParams({ ...existing, keywords: event.target.value }, { replace: true })
    }, 500)

    useEffect(() => {
        setGlobalFilter(searchParams.get('keywords'))
    }, [setGlobalFilter, searchParams])
  
    return (
        <p className={`${styles.field} ${styles.searchField}`}>
            <label htmlFor="search_keywords">{i18n.t('web.private.misc.search')}</label>
            <input type="search" id="search_keywords" name="keywords" placeholder={i18n.t('web.private.misc.search_n_records', { count: count.toLocaleString(locale) })} value={searchParams.get('keywords') || ''} onChange={onChange} />
        </p>
    )
}

KeywordGlobalFilter.propTypes = {
    preGlobalFilteredRows: PropTypes.array.isRequired,
    setGlobalFilter: PropTypes.func,
    locale: PropTypes.string.isRequired
}

const ArchiveFilter = ({setFilter}) => {
    const i18n = useI18n()

    const onChange = (e) => {
        setFilter('isArchived', '' + e.target.checked)
    }

    return (
        <p className={`${styles.field} ${styles.archiveField}`}>
            <input type="checkbox" className={styles.toggleBox} id="archive_checkbox" onChange={onChange} />
            <label htmlFor="archive_checkbox">{i18n.t('web.private.misc.show_archived')}</label>
        </p>
    )
}

ArchiveFilter.propTypes = {
    setFilter: PropTypes.func
}

const MultiSelectColumnFilter = ({ setFilter, preFilteredRows, id }) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const filterElement = useRef()

    const closeFilter = () => {
        filterElement.current.removeAttribute('open')
    }

    useOnClickOutside(filterElement, closeFilter)

    useEffect(() => {
        setFilter(id, searchParams.getAll(id) || [])
    }, [setFilter, searchParams, id])

    const options = useMemo(() => {
        const options = new Set()
        preFilteredRows.flatMap(row => row.values[id]).forEach(value => {
            options.add(value)
        })
        return [...options.values()].sort()
    }, [id, preFilteredRows])

    const handleFilterChange = (event) => {
        const current = searchParams.getAll(id) || []
        const existing = searchParamsToObject(searchParams)
        if (event.target.checked) {
            setSearchParams({ ...existing, [id]: current.concat([event.target.value]) }, { replace: true })
        } else {
            setSearchParams({ ...existing, [id]: current.filter(x => x !== event.target.value) }, { replace: true })
        }
    }
  
    return (
        <details ref={filterElement}>
            <summary>{searchParams.getAll(id)?.length || 0} selected</summary>
            <ol id="filter_device">{options.filter((option) => option && option.trim() !== '').map((option, i) => (
                <li key={i}>
                    <label htmlFor={`filter_${id}_${i}`}>{option}</label>
                    <input type="checkbox" id={`filter_${id}_${i}`} value={option} checked={searchParams.getAll(id).includes(option)} onChange={handleFilterChange} />
                </li>))}
            </ol>
        </details>
    )
}

MultiSelectColumnFilter.propTypes = {
    setFilter: PropTypes.func.isRequired,
    preFilteredRows: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired
}

const DateRangeColumnFilter = ({ setFilter, preFilteredRows, id, locale, showCustomDateFilter }) => {
    const i18n = useI18n()
    const [searchParams, setSearchParams] = useSearchParams()

    const DEFAULT_RANGE = 'days-28'

    const monthMinus = (ago) => {
        const d = new Date()
        d.setDate(1)
        d.setMonth(d.getMonth() - ago)
        return d.toLocaleDateString(locale, { year: 'numeric', month: 'long' })
    }

    const yearMinus = (ago) => {
        const d = new Date()
        d.setDate(1)
        d.setYear(d.getFullYear() - ago)
        return d.toLocaleDateString(locale, { year: 'numeric' })
    }
  
    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)
        let max = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)
    
        preFilteredRows.forEach(row => {
          const rowDate = new Date(row.values[id])
    
          min = rowDate <= min ? rowDate : min
          max = rowDate >= max ? rowDate : max
        })
    
        return [min, max]
    }, [id, preFilteredRows])

    /**
     * Set the table filter using current range, allowing for time being
     * start of day in past, and end of day in future.
     * @param {string} id 
     * @param {Array<Date>} range 
     */
    const setFilterAllowingForTime = useCallback((id, range) => {
        if (!id || !range) return

        const start = new Date(range[0] || min)
        start.setHours(0, 0, 0, 0)

        const end = new Date(range[1] || max)
        end.setHours(23, 59, 59, 999)

        setFilter(id, [start, end])
    }, [setFilter, min, max])

    const handlePresetChange = (value) => {
        const existing = searchParamsToObject(searchParams)
        if (value !== 'custom') {
            delete existing.from
            delete existing.to
        }
        setSearchParams({ ...existing, preset: value }, { replace: true })
    }

    const handleChangeCustomRange = (from, to) => {
        const existing = searchParamsToObject(searchParams)
        setSearchParams({ ...existing, from: from, to: to}, { replace: true })
    }

    const dateValue = (name) => {
        const defaultValue = name === 'from' ? min : max
        return searchParams.get(name) || defaultValue.toISOString().substring(0, 10)
    }

    useEffect(() => {
        const preset = searchParams.get('preset') || DEFAULT_RANGE

        if (preset === 'custom') {
            setFilterAllowingForTime(id, [searchParams.get('from'), searchParams.get('to')])
        } else {
            setFilterAllowingForTime(id, DATE_OPTIONS[preset])
        }
    }, [searchParams, setFilterAllowingForTime, id])

    return (
        <p className={styles.field}>
            { searchParams.get('preset') === 'custom' ?
                <span style="display: flex; align-items: center;" aria-live="polite">
                    <input
                        type="date"
                        aria-label="Date from"
                        min={min.toISOString().slice(0, 10)}
                        max={dateValue('to')}
                        id={`filter_${id}`}
                        value={dateValue('from')}
                        onChange={e => handleChangeCustomRange(e.target.value, dateValue('to'))}
                    /> - <input
                        type="date"
                        aria-label="Date to"
                        min={dateValue('from')}
                        max={new Date().toISOString().substring(0, 10)}
                        id={`filter_${id}_to`}
                        value={dateValue('to')}
                        onChange={e => handleChangeCustomRange(dateValue('from'), e.target.value)}
                    />
                    <button onClick={() => handlePresetChange(DEFAULT_RANGE)} style="min-width: unset;" aria-label={i18n.t('web.private.misc.close')}>
                        <Icon name="close" /> {i18n.t('web.private.misc.close')}
                    </button>
                </span>
            :
                <select value={searchParams.get('preset') || DEFAULT_RANGE} onChange={(e) => handlePresetChange(e.target.value)} aria-label="Date range">
                    <optgroup label={i18n.t('web.private.misc.relative')}>
                        <option value="days-7">{i18n.t('web.private.misc.last_7_days')}</option>
                        <option value="days-14">{i18n.t('web.private.misc.last_14_days')}</option>
                        <option value="days-28">{i18n.t('web.private.misc.last_28_days')}</option>
                    </optgroup>

                    <optgroup label={i18n.t('web.private.misc.milestones')}>
                        {/* <option value="weeks-0">{i18n.t('web.private.misc.this_week')}</option>
                        <option value="weeks-1">{i18n.t('web.private.misc.last_week')}</option> */}
                        <option value="months-0">{i18n.t('web.private.misc.this_month')}</option>
                        <option value="months-1">{i18n.t('web.private.misc.last_month')}</option>
                    </optgroup>

                    <optgroup label={i18n.t('web.private.misc.older')}>{ [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].map( i => (
                        <option key={i} value={`months-${i}`}>{monthMinus(i)}</option>)) }
                    </optgroup>

                    { showCustomDateFilter && <optgroup label={i18n.t('web.private.misc.years')}>
                        <option value="years-0">{i18n.t('web.private.misc.this_year')}</option>
                        { [1, 2, 3, 4].map( i => (
                        <option key={i} value={`years-${i}`}>{yearMinus(i)}</option>)) }
                    </optgroup> }
                    
                    { showCustomDateFilter && <optgroup label={i18n.t('web.private.misc.other')}>
                        <option value="custom">{i18n.t('web.private.misc.custom_range')}</option>
                    </optgroup> }
                </select>
            }
        </p>
    )
}

const dateBetweenFilterFunction = (rows, id, filterValues) => {
    let sd = new Date(filterValues[0])
    let ed = new Date(filterValues[1])
    return rows.filter(r => {
        var time = new Date(r.values[id]);
        if (filterValues.length === 0) return rows
        return (time >= sd && time <= ed)
    })
}

DateRangeColumnFilter.propTypes = {
    setFilter: PropTypes.func.isRequired,
    preFilteredRows: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    showCustomDateFilter: PropTypes.bool
}

const defaultPropGetter = () => ({})

export function DataTable ({children, prefilter = false, showBackLink = false, dummy = false, columns, data, selected = {}, pageSize = 250, locale = 'en-gb', searchable = true, exportable = true, getRowProps = defaultPropGetter, showCustomDateFilter = false}) {
    const i18n = useI18n()

    const more = 10
    const columnData = useMemo(() => columns, [columns])
    const [searchParams, setSearchParams] = useSearchParams()
    const rowData = useMemo(() => data, [data])

    const toggleShowFilters = () => {
        if (searchParams.get('showFilters')) {
            searchParams.delete('showFilters')
            setSearchParams(searchParams, { replace: true })
        } else {
            const existing = searchParamsToObject(searchParams)
            setSearchParams({ ...existing, showFilters: 'true' }, { replace: true })
        }
    }

    const table = useTable({
        columns: columnData,
        data: rowData,
        initialState: { 
            pageIndex: 0,
            pageSize: pageSize,
            hiddenColumns: columnData.filter(column => column.isVisible === false).map(column => column.id || column.accessor),
            // TODO: Make the prefilter function dynamic as won't be right for all data passed in
            filters: useMemo(() => {
                const filters = [
                    { id: 'isArchived', value: 'false' },
                    ...columnData.filter(c => c.filter !== undefined && searchParams.has(c.id || c.accessor)).map(c => { return { 
                        id: c.id || c.accessor,
                        value: searchParams.getAll(c.id || c.accessor)
                    }}),
                ]
                let range = null
                if (prefilter) {
                    if (searchParams.get('preset') !== 'custom' && DATE_OPTIONS[searchParams.get('preset')]) {
                        range = DATE_OPTIONS[searchParams.get('preset')]
                    } else if (searchParams.get('preset') === 'custom' && searchParams.get('from') && searchParams.get('to')) {
                        range = [searchParams.get('from'), searchParams.get('to')] 
                    }
                    if (range) {
                        filters.push({ id: 'timestamp', value: range })
                    }
                }
                return filters
            }, [columnData, searchParams, prefilter]),
            selectedRowIds: selected
        },
        filterTypes: useMemo(() => ({
            dateBetween: dateBetweenFilterFunction
        }), []),
        autoResetPage: false,
        autoResetExpanded: false,
        autoResetGroupBy: false,
        autoResetSelectedRows: false,
        autoResetSortBy: false,
        autoResetGlobalFilter: false,
        autoResetFilters: false,
        autoResetRowState: false
    }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect)

    // TODO: Need better way of determing the prefilter column(s) to ignore...
    const haveFilters = columns.some(c => c.accessor !== 'timestamp' && c.filter !== undefined)
    const activerUserFilterCount = useMemo(() => {
        return table.state.filters.filter(f => f.id !== 'timestamp' && f.id !== 'isArchived').length
    }, [table.state.filters])

    const exportData = () => {
        const cells = table.columns.filter(c => c.id !== 'id' && !c.unexportable )
        const dataToExport = [
            cells.map(c => c.Header),
            ...table.rows.map(row => cells.map(cell => row.values[cell.id]))
        ]
        const csv = Papa.unparse(dataToExport)

        const link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        link.setAttribute('download', `export-${new Date().toISOString()}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            { (prefilter || searchable || exportable || showBackLink || columns.some((c) => c.accessor === 'isArchived') || children) && <OptionBar>
                <div>
                { showBackLink &&  <BackLink /> }
                { prefilter && <DateRangeColumnFilter id="timestamp" setFilter={table.setFilter} preFilteredRows={table.preFilteredRows} locale={locale} showCustomDateFilter={showCustomDateFilter} /> }
                { searchable && <KeywordGlobalFilter preGlobalFilteredRows={table.preGlobalFilteredRows} setGlobalFilter={table.setGlobalFilter} locale={locale}/> }
                </div>
                { columns.some((c) => c.accessor === 'isArchived') && <ArchiveFilter setFilter={table.setFilter} preFilteredRows={table.preFilteredRows} /> }
                <div>
                    { haveFilters && <button onClick={toggleShowFilters} className={styles.filterButton}>{i18n.t('web.private.misc.filter')} { (activerUserFilterCount > 0) && <span className="badge">{activerUserFilterCount}</span> } <Icon name={searchParams.get('showFilters') ? 'fold' : 'unfold'} /></button> }
                    {children}
                    { exportable && <button className="non-critical" onClick={() => exportData()}><Icon name="download" /> {i18n.t('web.private.misc.export')}</button> }
                </div>
            </OptionBar> }

            {haveFilters && searchParams.get('showFilters') &&
            <div className={styles.filters}>
                { columns.map(column => {
                    let FilterComponent
                    switch (column.filter) {
                        case 'dateRange':
                            FilterComponent = <DateRangeColumnFilter id={column.id || column.accessor} setFilter={table.setFilter} preFilteredRows={table.preFilteredRows} locale={locale} />
                            break
                        case 'includesSome':
                            FilterComponent = <MultiSelectColumnFilter id={column.id || column.accessor} setFilter={table.setFilter} preFilteredRows={table.preFilteredRows} />
                            break
                        default:   
                    }
                    return (
                        FilterComponent && <p>
                            <label htmlFor={`filter_${column.id || column.accessor}`}>{column.Header}</label>
                            {FilterComponent}
                         </p>
                    )
                })}
            </div>
            }

            <table {...table.getTableProps()} className={styles.dataTable} data-dummy={dummy}>
                <thead>{table.headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>{headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps} className={styles[column.className]} key={column.id}>{column.render('Header')}</th>))}
                    </tr>))}
                </thead>
                <tbody {...table.getTableBodyProps()}>{table.page.map(row => {
                    table.prepareRow(row)
                    return (
                        <tr {...row.getRowProps(getRowProps(row))} key={row}>{row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()} className={styles[cell.column.className]} key={cell}>{cell.render('Cell')}</td>
                            )})}
                        </tr>
                    )})}
                </tbody>
            </table>

            { table.canNextPage ?
                <div className={styles.pagination}>
                    <button type="button" onClick={() => table.setPageSize(table.state.pageSize + more)}>
                        {i18n.t('web.private.misc.show_more')} 
                    </button>
                </div>
                :
                <div>&nbsp;</div>
            }

            { table.page.length === 0 &&
                <p className={styles.empty}>{i18n.t('web.private.misc.nothing_to_display')}</p>
            }
        </>
    )
}

DataTable.propTypes = {
    children: PropTypes.node,
    prefilter: PropTypes.bool,
    dummy: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    pageSize: PropTypes.number,
    locale: PropTypes.string,
    searchable: PropTypes.bool,
    exportable: PropTypes.bool,
    selected: PropTypes.object,
    getRowProps: PropTypes.func,
    showCustomDateFilter: PropTypes.bool,
    showBackLink: PropTypes.bool
}
