import PropTypes from 'prop-types'
import Icons from './icons.svg?react'

export const IconProvider = ({ children }) => {

    return <>
        {children}
        <div style={{display: 'none'}}><Icons /></div>
    </>
}

IconProvider.propTypes = {
    children: PropTypes.node.isRequired
}