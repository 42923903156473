import PropTypes from 'prop-types'

import styles from './container.module.css'

export const Container = ({ children }) => {
  return (
    <main className={styles.Container}>
      {children}
    </main>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired
}