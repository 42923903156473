import PropTypes from 'prop-types'

import { useI18n } from '@/context'
import { Link } from 'react-router-dom'
import { Image, Barcode } from '@/components'

import styles from './detail-table.module.css'

export const DeviceDetailTable = ({ device }) => {
    const i18n = useI18n()

    return (
        <>
            <div className={styles.logDetail}>
                <div className={styles.productImage}>
                    <Image filename={`device-${device.id}-image`} alt={device.tag}>
                            { device.type ? 
                                <Image filename={`device-type-${device.type.id}-image`} alt={device.type.name}>
                                    <span className="no-image">{i18n.t('web.private.misc.no_image_available')}</span>
                                </Image>
                            :
                                <span className="no-image">{i18n.t('web.private.misc.no_image_available')}</span>
                            }
                    </Image>
                </div>
                <table className={styles.detailTable}>
                    <tbody>
                        <tr>
                            <th>{i18n.t('web.private.misc.table.header.tag')}</th>
                            <td>{device.tag}</td>
                        </tr>
                        <tr>
                            <th>{i18n.t('web.private.misc.table.header.type')}</th>
                            <td>
                                <Link to={`../../?type.name=${device.type.name}`}>
                                    {device.type.name}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <th>{i18n.t('web.private.misc.table.header.manufacturer')}</th>
                            <td>
                                <Link to={`../../?manufacturer.name=${device.manufacturer.name}`}>
                                    {device.manufacturer.name}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <th>{i18n.t('web.private.misc.table.header.model')}</th>
                            <td>{device.model}</td>
                        </tr>
                        <tr>
                            <th>{i18n.t('web.private.misc.table.header.serial_number')}</th>
                            <td>{device.serialNumber}</td>
                        </tr>
                        <tr>
                            <th>{i18n.t('web.private.misc.table.header.barcode_number')}</th>
                            <td><Barcode type="qrcode" width={150} input={device.barcode} /></td>
                        </tr>
                        {/* <tr>
                            <th>{i18n.t('web.private.misc.table.header.eva')}</th>
                            <td>{device.isEva ? i18n.t('web.private.misc.yes') : i18n.t('web.private.misc.no')}</td>
                        </tr> */}
                        <tr>
                            <th>{i18n.t('web.private.misc.table.header.clinical_setting')}</th>
                            <td>{device.clinicalSettings?.map(s => s.name).join(', ') || i18n.t('web.private.misc.not_specified')}</td>
                        </tr>
                        <tr>
                            <th>{i18n.t('web.private.misc.table.header.product_system')}</th>
                            <td>{device.productSystems.map(ps => ps.name).join(', ')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

DeviceDetailTable.propTypes = {
    device: PropTypes.object.isRequired,
    mutate: PropTypes.func
}