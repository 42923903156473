import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AI_CONNECTION_STRING } from '@/utils'

let insights

if (AI_CONNECTION_STRING != '') {
    insights = new ApplicationInsights({ config: {
        connectionString: AI_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        disableCookiesUsage: true
    } })
    insights.loadAppInsights()
    insights.trackPageView() // Manually call trackPageView to establish the current user/session/pageview
}

const InsightsContext = createContext()

export const InsightsProvider = ({ children }) => {

    return (
        <InsightsContext.Provider value={insights}>
            {children}
        </InsightsContext.Provider>
    )
} 

InsightsProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export const useInsights = () => {
    const context = useContext(InsightsContext)
    return context
}