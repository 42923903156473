import { createContext, useContext, useCallback, useRef, useState, memo } from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@/components'
import { useOnClickOutside } from '@/hooks'

const ToastContext = createContext()

const TOAST_TIMER = 10000

const Toast = memo(({ children, nature = 'info', onClick = () => {}, options = []}) => {
    const toastElement = useRef()
    useOnClickOutside(toastElement, onClick)

    return (
        <div ref={toastElement} className="toast" role="status" data-nature={nature}>
            <div className="icon"><Icon name={nature} size={48} /></div>
            <div className="message">{children}</div>
            { options && options.length > 0 && <ul className="choices">
                { options.map(option => (
                    <li key={option.text}><button className="standard" onClick={option.callback || onClick}>{option.text}</button></li>
                )) }
            </ul> }
        </div>
    )
})

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([])

    const dismissToast = () => {
        setToasts(toasts => toasts.slice(1))
    }

    const toast = (message, nature = 'info', options = []) => {
        setToasts(toasts => [...toasts, {message, nature, options}])
        if (options.length === 0) {
            setTimeout(dismissToast, TOAST_TIMER)
        }
    }

    const onClick = useCallback(() => {
        dismissToast()
    }, [])

    return (
        <ToastContext.Provider value={toast}>
            {children}
            <div id="toaster">
                {toasts.map((t) => <Toast key={t} nature={t.nature} onClick={onClick} options={t.options}>{t.message}</Toast>)}
            </div>
        </ToastContext.Provider>
    )
}

Toast.propTypes = {
    children: PropTypes.node.isRequired,
    nature: PropTypes.string,
    onClick: PropTypes.func,
    options: PropTypes.array
} 

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export const useToast = () => {
    const context = useContext(ToastContext)
    if (!context) throw Error('useToast should be used within <ToastProvider />')
    return context
}