import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { CircleFlag } from 'react-circle-flags'

import { useAuth, useI18n } from '@/context'
import { updateUser } from '@/api'

import styles from './language-selector.module.css'

export const LanguageSelector = ({languages}) => {
    const i18n = useI18n()
    const auth = useAuth()
    const location = useLocation()
    
    const determineNewRoute = (targetLocale) => {
        return location.pathname.replace(i18n.locale.toLowerCase(), targetLocale.toLowerCase()) + location.search
    }

    const handleLocaleChange = (code) => {
        i18n.changeLocale(code)
        i18n.toggleLanguageSelector()

        if (auth && auth.user) {
            updateUser({
                id: auth.user.id,
                cultureId: code
            })
        }
    }

    return (
        languages && <ul className={styles.languages}>{languages.map(({id, language}) => (
            <li key={id}>
                <NavLink to={determineNewRoute(id)} onClick={() => handleLocaleChange(id)}>
                    <CircleFlag countryCode={id.toLowerCase().slice(-2)} width="32" height="32" cdnUrl="/assets/flags/" />
                    {language}
                </NavLink>
            </li>))}
        </ul>
    )
}

LanguageSelector.propTypes = {
    languages: PropTypes.array.isRequired
}