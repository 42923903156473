import { ResponsiveLine } from '@nivo/line'

import PropTypes from 'prop-types'

export const SparkLine = ({ data, height = '64px', color = '#D3277C' }) => {
    return data ? (
        <div style={{ height: height }}>
            <ResponsiveLine
                data={data}
                role="presentation"
                pointLabelYOffset={0}
                enableArea={true}
                curve="monotoneX"
                colors={[color]}
                theme={{
                    axis: {
                        ticks: { line: { strokeWidth: 0 } },
                        domain: { line: { strokeWidth: 0 } }
                    },                        
                    grid: { line: { strokeWidth: 0 } }
                    }}
                margin={{ top: 5, right: 0, bottom: 0, left: 0 }}
            />
        </div>
    ) : null
}

SparkLine.propTypes = {
    data: PropTypes.array,
    height: PropTypes.number,
    color: PropTypes.string
}