import PropTypes from 'prop-types'

import { Spinner } from './Spinner'

const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '12rem',
    flexGrow: '1'
}

export const BlockSpinner = ( { height }) => {
    return (
      <>
        <div style={{...style, height}}>
            <Spinner width={128} height={128} />
        </div>
      </>
    )
}

BlockSpinner.propTypes = {
  height: PropTypes.string
}
