import PropTypes from 'prop-types'
import { Navigate, useLocation } from "react-router-dom";

import { useAuth, useI18n, useScope } from '@/context'
import {
  resourceInappropriateForScopeType,
  resourceInappropriateForUser,
  scopeInappropriateForUser
} from '@/config'
import NotFoundPage from '@/pages/404'

const LOCALE_PATTERN = /^\/[a-zA-Z]{2}-[a-zA-Z]{2}/
const PUBLIC_PATHS = ['/login/', '/login-changed/', '/logged-out/', '/register/']

const isPublic = (path) => {
  return PUBLIC_PATHS.some( (candidate) => path.replace(LOCALE_PATTERN, '').startsWith(candidate) )
}

const isProtected = (path) => {
  return !(isPublic(path))
}

export const Guard = ({ children }) => {
  const auth = useAuth()
  const i18n = useI18n()
  const scope = useScope()
  const location = useLocation()

  const path = location.pathname.toLowerCase()
  const resource = path.substring(1, path.length-1).split('/')[2]

  if (isProtected(path)) {
    if (!auth || !auth.user) return <Navigate to={`/${i18n.locale}/login/`} replace={true} />
    if (scope && scopeInappropriateForUser(scope, auth.user)) return <NotFoundPage />
    if (scope && resource && resourceInappropriateForScopeType(resource, scope.type)) return <NotFoundPage />
    if (resource && resourceInappropriateForUser(resource, auth.user)) return <NotFoundPage />
  }

  return <>{children}</>
}

Guard.propTypes = {
  children: PropTypes.node.isRequired
}