import PropTypes from 'prop-types'

export const Fieldset = ({ children, legend, show = true }) =>  {

    return show ? (
        <fieldset>
            {legend && <legend><span>{legend}</span></legend>}
            {children}
        </fieldset>
    ): null
}

Fieldset.propTypes = {
    children: PropTypes.node.isRequired,
    legend: PropTypes.string.isRequired,
    show: PropTypes.bool 
}