import PropTypes from 'prop-types'

import styles from './panel.module.css'

export const Panel = ({ children, variant = 'default', style}) => {
    return (
      <div className={styles.panel} data-variant={variant} style={style}>
        {children}
      </div>
    )
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'solo', 'login', 'register', 'stat', 'auto', 'door', 'splash', 'banner', "error"]),
  style: PropTypes.string
} 