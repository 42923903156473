import { useI18n } from "@/context"
import { Panel } from "@/components"

export default function NotFoundPage() {
  const i18n = useI18n()

  return (
    <div className="pagelet" role="main">
      <Panel>
        <h1 style="text-align: center;">{i18n.t('web.private.page.page_not_found.title')}</h1>

        <div style="text-align: center; margin: 2rem 0 0 0;">
          <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
            <line x1="12" y1="11" x2="12" y2="14"></line>
          </svg>
          <p><a href="/">{i18n.t('web.private.page.page_not_found.go_home')}</a></p>
        </div>
      </Panel>
    </div>
  )
}