import PropTypes from 'prop-types'
import { Panel } from '@/components'

export const DummyPanel = ({ children, variant = 'default' }) => {
    return (
        <Panel variant={variant}>
            <h2>This is a temporary test panel</h2>
            {children}
        </Panel>
    )
}

DummyPanel.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(['default', 'solo'])
}