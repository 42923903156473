export const DESTINATION_PATIENT = 'PatientReady'
export const DESTINATION_READY = 'DeviceReady'
export const DESTINATION_STORAGE = 'Storage'
export const DESTINATIONS = {
    [DESTINATION_STORAGE]: 'web.private.misc.device_destinations.storage',
    [DESTINATION_PATIENT]: 'web.private.misc.device_destinations.patient',
    [DESTINATION_READY]: 'web.private.misc.device_destinations.ready'
}

export const ORGANISATION_ID_LENGTH = 36
export const TRISTEL_MASTER_ORG_ID = '00000000-0000-0000-0000-000000000000'

export const getDurationOptions = (i18n) => {
    return [
        { value: 0, label: i18n.t('web.private.misc.durations.0s') },
        { value: 10, label: i18n.t('web.private.misc.durations.10s') },
        { value: 15, label: i18n.t('web.private.misc.durations.15s') },
        { value: 30, label: i18n.t('web.private.misc.durations.30s') },
        { value: 45, label: i18n.t('web.private.misc.durations.45s') },
        { value: 60, label: i18n.t('web.private.misc.durations.60s') },
        { value: 90, label: i18n.t('web.private.misc.durations.90s') },
        { value: 120, label: i18n.t('web.private.misc.durations.120s') },
        { value: 180, label: i18n.t('web.private.misc.durations.180s') }
    ]
}

export const ERROR_CODES = {
    CONFLICT: 409
}