import { API_BASE } from '@/utils'

const callPublicEndpoint = async (endpoint, data) => {
    return fetch(`${API_BASE}/${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    }).then( response => {
        if (response.ok) {
            if (response.status == 204) {
                // No content in response but is success so...
                return true
            } else {
                return response.json()
            }
        } else {
            if (response.status == 400 || response.status == 401) {
                return false
            } else {
                throw new Error('Unexpected response code: ' + response.status)
            }
        }
    })
}

export const callLogin = async (data) => {
    return callPublicEndpoint('v1/Weblogin', data)
}

export const callCheckPasswordCode = async (code) => {
    return callPublicEndpoint('v1/CheckPasswordCode', { code: code })
}


export const callRequestPasswordReset = async (email) => {
    return callPublicEndpoint('v1/RequestPasswordReset', { email: email })
}

export const callResetPassword = async (data) => {
    return callPublicEndpoint('v1/ChangePasswordUsingCode', data)
}