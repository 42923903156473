import { useAuth } from "@/context"
import { isPermitted } from "@/config"
import PropTypes from 'prop-types'

export const Authorised = ({ children, action, resource}) => {
    const auth = useAuth()

    return isPermitted(auth.user, action, resource) ? children : null
}

Authorised.propTypes = {
    children: PropTypes.node.isRequired,
    action: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired
}