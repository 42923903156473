import { callAPI, generateSimplePatch } from "."

export const getOrganisations = async (filters) => {
    return callAPI('v1/GetOrganisations', 'GET', null, filters)
}

export const getOrganisationsForCountry = async (countryId) => {
    return getOrganisations({ masterOrganisation: { country: { iso3166: countryId.toUpperCase() } } })
}

// TODO: move to v2 endpoint once it is complete
export const getOrganisation = async (id) => {
    const orgs = await getOrganisations({id: id})
    return orgs ? orgs[0] : null
}

export const getOrganisationAnonymously = async (id) => {
    return callAPI(`v2/organisations/${id}/anonymous`, 'GET')
}

/**
 * Temp function to use new v2 endpoint whici is incomplete, until can move original function to use it fully
 * @param {string} organisationId 
 * @returns prefs for an org (so far, more in future)
 */
export const getOrganisation2 = async (organisationId) => {
    return callAPI(`v2/organisations/${organisationId}`, 'GET', null)
}

// TODO:API This isn't really good enough - and wouldn't this be better done server side anyway?!?
export const generateAppCode = (chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789', length = 8) => {
    return [...Array(length)].map( () => {
        return chars[Math.floor(Math.random() * chars.length)]
      }).join('')
}

export const addOrganisation = async (params) => {
    return callAPI('v1/AddOrganisation', 'POST', params)
}

export const setOrganisationType = async (organisationId, type) => {
    return callAPI(`v2/organisations/${organisationId}/type`, 'PUT', type)
}

export const setOrganisationClinicalDepartments = async (organisationId, depts) => {
    return callAPI(`v2/organisations/${organisationId}/clinical-departments`, 'PUT', depts)
}

export const addProductToOrganisation = async (params) => {
    return callAPI('v1/AddProductToOrganisation', 'POST', params)
}

export const removeProductFromOrganisation = async (params) => {
    return callAPI('v1/DeleteProductFromOrganisation', 'POST', params)
}

export const updateOrganisation = async (params) => {
    if (!params || !params['id']) {
        throw new Error('Expected id parameter when updating an organisation')
    }

    return callAPI('v1/UpdateOrganisation', 'POST', params)
}

/**
 * Temporary function for patching organisations only to be used for setting systems until extended in future to do all of org update
 * @param {Object} original
 * @param {Object} submission
 */
export const patchOrganisation = async (organisationId, original, submission) => {
    const patch = generateSimplePatch(original, submission)
    return callAPI(`v2/organisations/${organisationId}`, 'PATCH', patch)
}

export const archiveOrganisation = async (params) => {
    return updateOrganisation({
        id: params['id'],
        isArchived: true
    })
}

export const restoreOrganisation = async (params) => {
    return updateOrganisation({
        id: params['id'],
        isArchived: false
    })
}

export const getParentOrganisations = async (filters) => {
    return callAPI('v1/GetMasterOrganisations', 'GET', null, filters)
}

export const addParentOrganisation = async (params) => {
    return callAPI('v1/AddMasterOrganisation', 'POST', params)
}

export const updateParentOrganisation = async (params) => {
    if (!params || !params['id']) {
        throw new Error('Expected id parameter when updating a parent organisation')
    }

    return callAPI('v1/UpdateMasterOrganisation', 'POST', params)
}

