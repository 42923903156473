// TODO: temporary workaround until finalise how to handle this in each env

const API_URLS = {
    "localhost": "/api",
    "192.168.0.11": "/api",
    "3t.tristel.dev": "https://api.tristel.dev/api",
    "dev.tristelapp.cn": "https://api-dev.tristelapp.cn/api",
    "uat.3t.app": "https://api-uat.3t.app/api",
    "uat.tristelapp.cn": "https://api-uat.tristelapp.cn/api",
    "3t.app": "https://api.3t.app/api",
    "tristelapp.cn": "https://api.tristelapp.cn/api",
    "www.tristelapp.cn": "https://api.tristelapp.cn/api"
}

const BLOB_URLS = {
    "localhost": "https://blob.tristel.dev",
    "3t.tristel.dev": "https://blob.tristel.dev",
    "dev.tristelapp.cn": "https://blob-dev.tristelapp.cn",
    "uat.3t.app": "https://blob-uat.3t.app",
    "uat.tristelapp.cn": "https://blob-uat.tristelapp.cn",
    "3t.app": "https://blob.3t.app",
    "tristelapp.cn": "https://blob.tristelapp.cn",
    "www.tristelapp.cn": "https://blob.tristelapp.cn"
}

// Azure Application Insights
const AI_CONNECTION_STRINGS = {
    "localhost": "",
    "3t.tristel.dev": "InstrumentationKey=b4886108-98ae-4c8d-9285-52274ea077e9;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/",
    "uat.3t.app": "InstrumentationKey=5ccd0d62-03d0-426a-b061-b9cca6a64179;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
    "3t.app": "InstrumentationKey=309ba48c-b727-4505-8362-92d3566de5ad;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/"
}

const ENVIRONMENT_TAGS = {
    "localhost": "LOCAL",
    "3t.tristel.dev": "DEV",
    "dev.tristelapp.cn": "DEV",
    "uat.3t.app": "UAT",
    "uat.tristelapp.cn": "UAT",
    "3t.app": "",
    "tristelapp.cn": "",
    "www.tristelapp.cn": ""
}

export const API_BASE = API_URLS[window.location.hostname] || "https://api.tristel.dev/api"
export const BLOB_BASE = BLOB_URLS[window.location.hostname] || "https://blob.tristel.dev"
export const AI_CONNECTION_STRING = AI_CONNECTION_STRINGS[window.location.hostname] || ""
export const ENVIRONMENT_TAG = (ENVIRONMENT_TAGS[window.location.hostname] === '') ? '' : (ENVIRONMENT_TAGS[window.location.hostname] || "PREVIEW")