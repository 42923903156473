import { callAPI } from '.'

export const getCountries = async (filters) => {
    return callAPI('v1/GetCountries', 'GET', null, filters)
}

export const getCountriesForDataRegion = async (regionCode) => {
    const countries = await getCountries() 
    return countries.filter(country => country.regionCode.toLowerCase() === regionCode.toLowerCase())
}

export const getCountriesForSalesRegion = async (salesRegionId) => {
    const countries = await getCountries()
    return countries.filter(country => country.commercialZone.id == salesRegionId)
}

export const getCountry = async (iso3166) => {
    const countries = await callAPI('v1/GetCountries', 'GET')
    return countries.filter(country => country.iso3166.toLowerCase() === iso3166.toLowerCase())[0]
}

export const addCountry = async (params) => {
    return callAPI('v1/AddCountry', 'POST', params)
}

export const updateCountry = async (params) => {
    return callAPI('v1/UpdateCountry', 'POST', params)
}

export const getProductsForCountry = async (countryId) => {
    const results = await callAPI('v1/GetProductsByCountry', 'GET')
    return results.filter(r => r.country?.iso3166 === countryId && !r.country.isArchived && !r.product.isArchived && !r.isArchived)
}

export const getCountriesForProduct = async (productId) => {
    const results = await callAPI('v1/GetProductsByCountry', 'GET')
    return results.filter(r => r.product?.id === productId && !r.country.isArchived && !r.product.isArchived && !r.isArchived)
}

export const addProductToCountry = async (params) => {
    return callAPI('v1/AddProductToCountry', 'POST', params)
}

export const updateProductForCountry = async (params) => {
    return callAPI('v1/UpdateProductForCountry', 'POST', params)
}