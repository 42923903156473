import PropTypes from 'prop-types'
import { useEffect, useRef, useState  } from 'react'

import styles from './option-bar.module.css'

export const OptionBar = ({ children }) => {
    const theOptionBar = useRef()
    const [isStuck, setIsStuck] = useState(false)

    useEffect(() => {
      if (!theOptionBar) return
      const element = theOptionBar.current

      const observer = new IntersectionObserver( ([e]) => {
        setIsStuck(e.intersectionRatio < 1)
      }, { threshold: [1] })
      observer.observe(element)
    
      return () => {
        observer.unobserve(element)
      }
    }, [])

    return (
      <div ref={theOptionBar} className={styles.optionBar} data-stuck={isStuck}>
        {children}
      </div>
    )
}

OptionBar.propTypes = {
  children: PropTypes.node.isRequired
}