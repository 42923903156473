import { createContext, useCallback, useContext, useEffect, useRef, useState, memo } from 'react'
import PropTypes from 'prop-types'

import { useFocusTrap } from '@/hooks'

const PromptContext = createContext()

const Prompt = memo(({ heading, text, actions, close }) => {
    const dialogElement = useRef()
    useFocusTrap(dialogElement)

    return (
        <dialog open ref={dialogElement}>
            <form method="dialog">
                <fieldset>
                    <legend><span>{heading}</span></legend>
                    <p>{text}</p>
                    <ul className="actions">
                        <li><button type="button" onClick={() => close()} className="plain">Cancel</button></li>{ actions.map( action => (
                        <li key={action[0]}><button type="button" className={action[2] || 'standard'} onClick={() => { action[1](); close()}}>{action[0]}</button></li>)) }
                    </ul>
                </fieldset>
            </form>
        </dialog>
    )
})

Prompt.propTypes = {
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    actions: PropTypes.array
}

export const PromptProvider = ({ children }) => {
    const [prompt, setPrompt] = useState(null)

    const openPrompt = (heading, text, actions) => {
        setPrompt({heading, text, actions})
    }

    const handleKeyDown = useCallback((event) => {
        // Allow escape key to close prompt
        if (event.which == 27) {
            setPrompt(null)
            event.preventDefault()
        }
    }, [])

    useEffect(() => {
        document.documentElement.setAttribute('data-modal-open', prompt !== null)
        if (prompt) {
            document.addEventListener('keydown', handleKeyDown)
        } else {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [prompt, handleKeyDown])

    return (
        <PromptContext.Provider value={openPrompt}>
            {children}
            { prompt && <div id="prompter"><Prompt {...prompt} close={() => setPrompt(null)}/></div> }
        </PromptContext.Provider>
    )
} 

PromptProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export const usePrompt = () => {
    const context = useContext(PromptContext)
    if (!context) throw Error('usePrompt should be used within <PromptProvider />')
    return context
}