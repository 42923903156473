import { useEffect } from 'react'

export const useFocusTrap = (ref) => {
    useEffect(() => {
        if (!ref || !ref.current) return

        const theRef = ref.current

        const focusableElements = theRef.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        const firstFocusableElement = focusableElements[0]
        const lastFocusableElement = focusableElements[focusableElements.length - 1]
        const KEYCODE_TAB = 9

        theRef.setAttribute('tabindex', '-1')
        theRef.focus()

        const listener = theRef.addEventListener('keydown', (event) => {
            const isTabPressed = (event.key === 'Tab' || event.keyCode === KEYCODE_TAB)
            
            if (!isTabPressed) { 
                return
            }

            if ( event.shiftKey ) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus()
                    event.preventDefault()
                  }
                } else {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus()
                    event.preventDefault()
                }
            }
        })

        return () => {
            theRef.removeEventListener('keydown', listener)
        }
    }, [ref])
}
