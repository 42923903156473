import PropTypes from 'prop-types'

import styles from './data-grid.module.css'

export function DataGridBadge({text, variant}) {

    return (
        <span className={[styles.dataGridBadge, styles[variant] || 'default'].join(' ')}>{text}</span>
    )
}

DataGridBadge.propTypes = {
    text: PropTypes.string.isRequired, 
    variant: PropTypes.string
}