import { lazy, Suspense, Fragment } from 'react'
import { useRoutes } from 'react-router-dom'

const PRESERVED = import.meta.glob('/src/pages/404.jsx', { eager: true })
const ROUTES = import.meta.glob('/src/pages/**/[_a-z[]*.jsx')

const preservedRoutes = Object.keys(PRESERVED).reduce((routes, key) => {
  const path = key.replace(/\/src\/pages\/|\.jsx$/g, '')
  return { ...routes, [path]: PRESERVED[key]?.default }
}, {})

const regularRoutes = Object.keys(ROUTES).sort((a, b) => {
  // Make sure route path list is ordered sensibly and consistently.
  // Must be shortest paths first, otherwise layouts not applied properly
  // Note, need to allow for [ being order before a-Z, which we don't want
  if (a.lastIndexOf('/') > b.lastIndexOf('/')) {
    return 1
  } else if (a.lastIndexOf('/') < b.lastIndexOf('/')) {
    return -1
  } else {
    return a.replaceAll(/\[/g, 'zz') > b.replaceAll(/\[/g, 'zz') ? 1 : -1
  }
}).filter(key => !key.endsWith('__layout.jsx')).reduce((routes, key) => {
  const Component = lazy(ROUTES[key])
  const route = {
    element: <Suspense><Component /></Suspense>
  }

  const segments = key
    .replace(/\/src\/pages|\.jsx$/g, '')
    .replace(/\[\.{3}.+\]/, '*')
    .replace(/\[(.+?)\]/g, ':$1')
    .split('/')
    .filter(Boolean)

  segments.reduce((parent, segment, index) => {
    const path = segment.replace(/index|\./g, '')
    const root = index === 0
    const leaf = index === segments.length - 1 && segments.length > 1
    const node = !root && !leaf
    const insert = /^$/.test(path) ? 'unshift' : 'push'

    if (root) {
      const ignored = path === '*'
      if (ignored) return parent

      const last = segments.length === 1
      if (last) {
        routes[insert]({ path, ...route })
        return parent
      }
    }

    if (root || node) {
      const current = root ? routes : parent.children
      const found = current?.find((route) => route.path === path)
      let layout

      if (found) {
        found.children ??= []
      } else {
        const layoutPath = key.replace(/(\[?[a-zA-Z0-9_]*\]?)\.jsx/, '') + '__layout.jsx'
        if (ROUTES[layoutPath]) {
          const LayoutComponent = lazy(ROUTES[layoutPath])
          layout = { element: <Suspense><LayoutComponent /></Suspense> }
        }
        current?.[insert]({path, ...layout, children: [] })
      }
      return found || (current?.[insert === 'unshift' ? 0 : current.length - 1])
    }

    if (leaf) {
      parent?.children?.[insert]({ path, ...route})
    }

    return parent
  }, {})

  return routes.sort( (a, b) => a.path || '' > b.path || '' )
}, [])

// console.debug('ROUTES:', ROUTES)
// console.debug('Routes:', regularRoutes)

export function Routes() {
  const NotFound = preservedRoutes?.['404'] || Fragment
  let element = useRoutes([...regularRoutes, { path: '*', element: <NotFound /> }])
  return element
}
