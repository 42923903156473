import PropTypes from 'prop-types'
import { createContext, useContext, useEffect } from 'react'
import Gleap from "gleap";

import { useI18n } from './i18n';

const SupportContext = createContext()

const GLEAP_API_KEY = 'xtvExhd6KJJNveaKS2z7WESkQSipGwQR'

export const SupportProvider = ({ children }) => {
    const i18n = useI18n()

    useEffect(() => {
        console.debug('Set Gleap language', i18n.locale)
        Gleap.setLanguage(i18n.locale)

        if(window.__GLEAP_INITIALISED) {
            console.debug('Soft Re-initialising Gleap', i18n.locale)
            Gleap.getInstance().softReInitialize()
        } else {
            console.debug('Initialising Gleap', i18n.locale)
            Gleap.setUseCookies(false);
            Gleap.setDisablePageTracking(true);
            Gleap.initialize(GLEAP_API_KEY);
            window.__GLEAP_INITIALISED = true
        }
    }, [i18n])

    return (
        <SupportContext.Provider value={Gleap}>
            {children}
        </SupportContext.Provider>
    )
} 

SupportProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export const useSupport = () => {
    return useContext(SupportContext)
}