import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { useAuth, useI18n, useScope } from '@/context'
import { canUseGlobalScope, canUseRegionalScope, canUseCountryScope, canUseMyOrgsScope, canUseOrganisationScope } from '@/config'
import { Icon } from '@/components'

import styles from './breadcrumbs.module.css'

export const BreadCrumbs = ({parent}) => {
    const scope = useScope()
    const auth = useAuth()
    const i18n = useI18n()

    return scope && auth && (
        <div className={styles.crumbs} data-print="false">
            <p>{i18n.t('web.public.misc.you_are_here')}</p>
            <ol>
                {canUseGlobalScope(auth.user) &&
                    <li><Icon name="crumb" /><Link to={`/${i18n.locale}/global/`}>{i18n.t('web.private.misc.global')}</Link></li>
                }

                {scope.region && canUseRegionalScope(scope.region, auth.user) && 
                    <li><Icon name="crumb" /><Link to={`/${i18n.locale}/${scope.region.id}-region/`}>{scope.region.name}</Link></li>
                }

                {scope.country && canUseCountryScope(scope.country, auth.user) &&
                    <li><Icon name="crumb" /><Link to={`/${i18n.locale}/${scope.country.iso3166.toLowerCase()}-country/`}>{scope.country.description}</Link></li>
                }

                {canUseMyOrgsScope(auth.user) && 
                    <li><Icon name="crumb" /><Link to={`/${i18n.locale}/my-orgs/`}>{i18n.t('web.private.misc.my_organisations')}</Link></li>
                }

                {(scope.organisation != null) && canUseOrganisationScope(scope.organisation, auth.user) &&
                    <li><Icon name="crumb" /><Link to={`/${i18n.locale}/${scope.organisation.id}/`}>{scope.organisation.name}</Link></li>
                }

                {scope.type === 'user' && 
                    <li><Icon name="crumb" /><Link to={`/${i18n.locale}/${scope.id}/`}>{auth.user.userInfo.firstName} {auth.user.userInfo.lastName}</Link></li>
                }

                {parent &&
                    <li><Icon name="crumb" /><Link to="../">{parent}</Link></li>
                }
            </ol>
        </div>
    )
}

BreadCrumbs.propTypes = {
    parent: PropTypes.string
}
