import PropTypes from 'prop-types'

import styles from './tool-bar.module.css'

export const ToolBar = ({children}) => {
    return (
        <div className={styles.toolBar}>{children}</div>
    )
}

ToolBar.propTypes = {
    children: PropTypes.node.isRequired
}