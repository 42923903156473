import { useEffect, Children } from 'react'

import PropTypes from 'prop-types'
import { BreadCrumbs } from '@/components'
import { ENVIRONMENT_TAG } from '@/utils/env'

export const Header = ({children, parent}) => {

    useEffect(() => {
        const title = Children.toArray(children).join('')
        document.title = `${ENVIRONMENT_TAG !== '' ? ENVIRONMENT_TAG + ': ' : ''}${title} - Tristel 3T`
    }, [children])
    
    return (
        <header>
            <div>
                <h1>{children}</h1>
                <BreadCrumbs parent={parent} />
            </div>
        </header>
    )
}

Header.propTypes = {
    children: PropTypes.node.isRequired,
    parent: PropTypes.string
}