import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import { useI18n } from '@/context'
import { Icon } from '@/components'

import styles from './modal.module.css'


export const Modal = forwardRef((props, ref) => {
    const modalRef = useRef()
    const i18n = useI18n()
    const [opened, setOpened] = useState(props.startOpen || false)

    const toggle = () => {
        if (opened  && props.onClose) props.onClose()
        setOpened(value => !value)
    }

    useImperativeHandle(ref, () => ({
        toggle: () => toggle(),
    }))

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Allow escape key to close modal
            if (event.which == 27) {
                setOpened(false)
                event.preventDefault()
            }
        }

        document.documentElement.setAttribute('data-modal-open', opened)
        if (opened) {
            document.addEventListener('keydown', handleKeyDown)
        } else {
            document.removeEventListener('keydown', handleKeyDown)
        }

        return () => { 
            document.removeEventListener('keydown', handleKeyDown)
            document.documentElement.setAttribute('data-modal-open', false)
        }
    }, [opened])

    return (opened && createPortal(
        <div className={styles.Modal} data-context="modal" data-variant={props.variant || 'normal'}>
            <div ref={modalRef} role="dialog" aria-modal="true" aria-label={props.title}>
                <header>
                    <h2>{props.title}</h2>
                    <button onClick={() => toggle()} aria-label={i18n.t('web.private.misc.close')}>
                        <Icon name="close" />
                    </button>
                </header>
                <div>
                    {props.children}
                </div>
                <footer>
                    <p>&nbsp;</p>
                </footer>
            </div>
        </div>,
        document.getElementById('app'))
    )
})

Modal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['normal', 'large', 'huge', 'full', 'fixed']),
    startOpen: PropTypes.bool,
    onClose: PropTypes.func
}
