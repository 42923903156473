import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client';
import { SWRConfig } from 'swr'
import { BrowserRouter } from 'react-router-dom'

import { Routes, Guard } from '@/config'
import { AuthProvider, I18nProvider, InsightsProvider, PromptProvider, ScopeProvider, SupportProvider, ToastProvider } from '@/context'
import { ErrorBoundary, IconProvider } from '@/components'
// import { localStorageProvider } from '@/api'

const swrConfig = {
  // provider: localStorageProvider,
  focusThrottleInterval: 1000 * 60 * 30, // 30 minutes
  dedupingInterval: 1000 * 30, // 30 seconds
  shouldRetryOnError: true,
  errorRetryCount: 2,
  onLoadingSlow: (key) => {
    console.warn(`API request for ${key} is taking a long time to respond`)
  },
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    if (error.message !== 'API BAIL') {
      if (retryCount > 2) {
        console.warn('Error when getting data (after retrying)', error)
        throw error
      } else {
        console.warn('Error when getting data (going to retry)', error)
        revalidate({ retryCount }, 2000 * retryCount)
      }
    }
  }
}

function Client() {

  // Note that Auth must wrap I18n as we need it when getting translations
  return (
    <StrictMode>
      <InsightsProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <SWRConfig value={swrConfig}>
              <AuthProvider>
                <I18nProvider>
                  <SupportProvider>
                    <IconProvider>
                      <ToastProvider>
                        <PromptProvider>
                          <ScopeProvider>
                            <Guard>
                              <Routes />
                            </Guard>
                          </ScopeProvider>
                        </PromptProvider>
                      </ToastProvider>
                    </IconProvider>
                  </SupportProvider> 
                </I18nProvider>
              </AuthProvider>
            </SWRConfig>
          </BrowserRouter>
        </ErrorBoundary>
      </InsightsProvider>
    </StrictMode>
  )
}

const container = document.getElementById('app')
const root = createRoot(container)
root.render(<Client />);