import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import QRCode from 'qrcode'

/**
 * Generate a barcode for display as an image
 * @param {object<string,any>} props props for this component
 * @param {string} props.type the type of barcode you want (one of code128, qrcode, or datamatrix) 
 * @param {string} props.input the input string to convert into a barcode of the specified type
 * @param {string?} props.label an optional text label to show under the barcode
 * @param {string?} props.width an option width for the barcode's overall box
 * @returns img element containing the barcode as a DataURL in its src attribute
 */
export const Barcode = ({ type = 'qrcode', input, label, width = 200, filename = 'qrcode' }) => {
    const [src, setSrc] = useState()

    useEffect(() => {
        const generateQRCode = (input) => {
            QRCode.toDataURL(input, { margin: 5, width: width }).then(url => {
                setSrc(url)
            }).catch(err => {
                console.error('QRCode Error', err)
            })
        }

        if (type == 'qrcode') {
            generateQRCode(input)
        } else if (type == 'code128') {
            //
        } else if (type == 'datamatrix') {
            //
        } else {
            throw new Error('Unsupported barcode requested')
        }
    }, [type, input, width])
    
    return src && <div style={`display: inline-block; padding: 0.5rem; background: #FFF; color: #000; border-radius: 0.5rem;`}><a href={src} download={`${filename}.png`} style={{ color: "#000" }}>
        <p style="margin: 0;"><img src={src} alt={input} /></p>
        { label && <p style="margin: 0 0 1rem 0; text-align: center;"><strong>{label}</strong></p> }
    </a></div>
}

Barcode.propTypes = {
    type: PropTypes.string,
    input: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    width: PropTypes.number,
    filename: PropTypes.string
}