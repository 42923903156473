import React from 'react'
import { ErrorInsight, Panel } from "@/components"

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {error: null}
    this.promiseRejectionHandler = this.promiseRejectionHandler.bind(this)
  }

  promiseRejectionHandler(event) {
    this.setState({error: event.reason})
  }

  static getDerivedStateFromError(error) {
    return { error: error }
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  errorIsDueToStaleApp(e) {
    // Missing dynamic import most likely means app in browser is stale so try to catch this
    const errorMessage = e.message
    if (!errorMessage) return false

    if (errorMessage.includes('dynamically imported module')
        || errorMessage.includes('module script failed')
        || errorMessage.includes('is not a valid JavaScript MIME type')) {
      return true
    }
  }

  componentDidCatch(error) {
    console.error('Error', error)
    // Try to catch situation where new deploy of code means code in browser is outdated.
    // In this situation the JS in browser might try load files that no longer exist
    // due to fingerprinting of resource (new ones having different fingerprints).
    // If we get an error loading a module script then this is the likely reason.
    // Forcing a full reload will get the latest code into the browser and then it should work.
    // TODO: check situation when request was a form POST (would rather not lose user's submission)
    // TODO: Make sure can't get stuck in infinite loop of reloads here
    if (this.errorIsDueToStaleApp(error)) {
      console.log('Reloading for new version of portal')
      window.location.reload()
    }
  }

  render() {
    const {error} = this.state

    if (error) {
      return !this.errorIsDueToStaleApp(error) ? (
        <div className="pagelet" style="text-align: center;">
          <Panel>
            <p style="margin: 1rem 0 1.5rem 0;">
              <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 24 24" fill="none" stroke="#EB3323" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
                  <line x1="12" y1="9" x2="12" y2="13"></line>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </p>
            <h1 style="margin: 0 0 1rem 0;">UNEXPECTED ERROR</h1>
            <p>Sorry, we can&apos;t show you the requested page</p>
            <p style={{ maxWidth: '24rem', color: '#FFF8' }}><em>{error.message}</em></p>
            <p><a href="/" style="color: #00DEB9;">Return to the home page and try again</a></p>
          </Panel>

          <ErrorInsight error={error} />
        </div>
      ) : null
    } else {
      return <>{this.props.children}</>
    }
  }
}     
       