import PropTypes from 'prop-types'

import styles from './forms.module.css'

const MAX_PROGRESS_STEPS_TO_SHOW = 10

export const Steps = ({ total = 1, current = 1, showProgress = true, children }) =>  {
    return (
        <div className={styles.steps}>
            <div>
                {children}
            </div>

            { (showProgress && (total > 1) && (total <= MAX_PROGRESS_STEPS_TO_SHOW)) && <ol className="non-critical">{Array.from({length: total}, (v, i) => i).map((i) => <li key={i} className={i == (current-1) ? styles.current : ''}>{i}</li>)}</ol> }
        </div>
    )
}

Steps.propTypes = {
    total: PropTypes.number,
    current: PropTypes.number,
    showProgress: PropTypes.bool,
    children: PropTypes.node.isRequired
}