import { useState } from 'react'
import PropTypes from 'prop-types'

import { BLOB_BASE } from '@/utils'

export const Image = ({children, filename, alt, width, height, extension = '.jpg', cacheBuster = Date.now()}) => {
    
    const [shouldTry, setShouldTry] = useState(true)

    const tryFallBack = () => {
        setShouldTry(false)
    }

    return shouldTry ?
       <img src={`${BLOB_BASE}/dynamic-assets/${filename}${extension}?t=${cacheBuster}`} alt={alt} width={width} height={height} onError={tryFallBack} />
       :
       children
} 

Image.propTypes = {
    children: PropTypes.node.isRequired,
    filename: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    extension: PropTypes.string,
    cacheBuster: PropTypes.number
}
